$pmbel-brand-color: #375988;


.pmbel{
  &--button{
    background-color: $pmbel-brand-color;
    color: white  !important;
    border:0;
    outline:0;
    padding: 0.3rem 2rem;
    font-size:16px;
    white-space: nowrap;
    height:40px;
  }
  &__login-box{
    box-sizing: border-box;
    *{
      box-sizing: border-box;
    }
    position: absolute;
    z-index: 100;
    left:0;
    top:50%;
    transform: translate3d(-100%,-50%,0);
    width:280px;
    height: 280px;
    background-color: white;
    padding: 2rem 1rem;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    animation-name: pmbel-slide-in-left;
    animation-duration: 0.6s;
    animation-delay: 3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    @media (min-width:768px) {
      display: flex;
    }
    &__headline{
      font-size:24px !important;
      line-height: 1;
      color: $pmbel-brand-color;
      font-weight: 700;
      text-align: left !important;
    }
    &__form{
      //border:1px dashed red;
    }
    &__input{
      background-color: #F2F2F2;
      color:#AAAAAA;
      border:0;
      padding:0.5rem 1rem;
      font-size:18px;
      margin-bottom: 20px;
      display: block;
      width:100%;
      height:40px;
    }
    &__footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      margin-top: 30px;
    }
    &__lost-password{
      color:#21283A;
      margin-bottom: 4px;
      text-align: left !important;
      a{
        display: block;
        line-height: 1.4;
        font-size:12px  !important;
      }
    }
  }

}

@keyframes pmbel-slide-in-left {
  0%{
    transform: translate3d(-100%,-50%,0);
  }
  100%{
    transform: translate3d(0%,-50%,0);
  }
}


.login-form-feedback-box {
  padding-top: 21px;
  visibility: hidden;
  padding: 0.5rem;
  color: #fff;
  font-size: 15px !important;
  font-weight: 700 !important;

  &.is-error{
    background-color: white !important;
    color: #c00 !important;
  }
  &.is-success{
    color: #34A900 !important;
    background-color: white !important;
  }
}


