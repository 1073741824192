:root{
  --per-accordion-background-color: white;
  --per-accordion-hovercolor: white;
  --perm-accordion-text-color: black;
  --perm-accordion-padding: 40px;
  --perm-accordion-border-color: #dfdfdf;
  --perm-accordion-header-font-size: 18px;
}
.perm {
  &-accordion{
    //border:1px solid blue;
    overflow: hidden;
    transform-origin: top;
    transition: max-height 1700ms;
    color: var(--perm-accordion-text-color,black);
    background-color: var(--per-accordion-background-color,white);
    &__header{
      //border:1px solid yellow;
      font-family: "myriad-pro", sans-serif;
      border-top: 1px solid var(--perm-accordion-border-color,grey);
      padding:1rem calc(var(--perm-accordion-padding,40px) + 2rem);
      min-height:1rem;
      text-transform: uppercase;
      position: relative;
      text-align: left;
      font-size: var(--perm-accordion-header-font-size,26px);

      &::-webkit-details-marker {
        color: transparent;
        width:0;
        height:0;
        display: none;
      }
      &::-moz-details-marker {
        color: transparent;
        width:0;
        height:0;
        display: none;
      }
      &::marker {
        color: transparent;
        width:0;
        height:0;
        display: none;
      }
      &:before{
        content:'';
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjMzE0MTZGIiBkPSJNMjU2IDI5NC4xTDM4MyAxNjdjOS40LTkuNCAyNC42LTkuNCAzMy45IDBzOS4zIDI0LjYgMCAzNEwyNzMgMzQ1Yy05LjEgOS4xLTIzLjcgOS4zLTMzLjEuN0w5NSAyMDEuMWMtNC43LTQuNy03LTEwLjktNy0xN3MyLjMtMTIuMyA3LTE3YzkuNC05LjQgMjQuNi05LjQgMzMuOSAwbDEyNy4xIDEyN3oiLz48L3N2Zz4=);
        width:20px;
        height:20px;
        position: absolute;
        left:var(--perm-accordion-padding,2rem);
        transition: transform 600ms;
      }
      &:hover{
        background-color: var(--per-accordion-hovercolor,#dedede);
      }
    }
    &__body{
      padding:0rem 1rem 1rem calc(var(--perm-accordion-padding,40px) + 2rem);
      transition: opacity 600ms;

    }
    &:not([open]) {
      min-height:2.68rem;
      > .perm-accordion__body {
        opacity:0;
      }

    }
    &[open] {
      //border:2px solid red;
      height:auto;
      max-height:100rem;
      outline: none;
      > .perm-accordion__body {
        opacity:1;
      }
      > .perm-accordion__header {
        &:before{
            transform: rotate(180deg);
        }
      }
    }
  }
}