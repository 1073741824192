$pmbel-brand-color: #375988;

.pmbel{
  &-slide{
   /* border:3px dashed yellow;*/
    /*background-color: $pmbel-brand-color;*/
    width:100%;
    height:100%;
    min-height:600px;
    //padding: 1rem var(--padding-side,1.5rem);

    &__inner{

    }
    &__background-image{
      position: absolute;
      z-index: -1;
      width:100%;
      height:100%;
      object-fit: cover;
    }
  }
  &-ad-container{
    position: absolute;
    z-index: 2;
    top:50%;
    transform: translateY(-50%);
    right:0rem;
    width: 100%;
    max-width: 536px;
    height:280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    color: black;
    padding: 1rem 2rem;
/*    > * + *{
      margin-bottom: 1rem;
    }*/
    &__headline{
      font-size: 48px;

      color: $pmbel-brand-color;
    }
    &__copy{
      font-size: 24px;
      line-height: 28px;
    }
    &__button{
      background-color: $pmbel-brand-color;
      color: white;
      border:0;
      outline:0;
      padding: 0.5rem 2rem;
      font-size:1rem;
      white-space: nowrap;
      height:40px;
    }
  }
}
