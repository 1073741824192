.pmbel{
  &-slider{
    /*border:1px dashed greenyellow;*/
     display: block;
     width:100%;
    &__inner{
      width:100%;
      height: 100%;
      overflow: hidden;
      max-height:100vh;
      @media (min-width: 1024px) {
        max-height:auto;
      }
    }
    &__dots-nav {
      position: absolute;
      z-index: 3;
      bottom: 6rem;
      right: 14rem;

      //left:50%;
      //transform: translate3d(-50%,0,0);

      > *{
        margin-right: 0.4rem;
      }
      .swiper-pagination-bullet{
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 100%;
        background-color: rgba(255,255,255,1);
        border: 1px solid white;
        opacity:0.5;
      }
      .swiper-pagination-bullet-active{
        background-color: rgba(255,255,255,1);
        opacity:1
      }
    }
  }
}
